import React from "react";
import { useSlots } from "@marbletech/bookit-core";
import { startOfToday } from "date-fns";

import { analytics } from "@flare/analytics";

import { funnelInputsNameMap } from "@/modules/v2/funnel/config";
import { BiEventsNames } from "@/services/analytics/event-names";

import { meetingType } from "../funnel.consts";
import { Scheduler } from "../Scheduler/Scheduler";

import { groupByDate } from "./LssScheduler.utils";

export function LssSchedulerWithRequest() {
  const from = startOfToday();
  const { isLoading, data } = useSlots({
    enabled: true,
    from,
  });

  const slots = groupByDate(data?.slots);

  function onDayChange(selectedDay: string) {
    const selectedDayIndex = Object.keys(slots).indexOf(selectedDay);

    const timeSlots = slots[selectedDay];
    analytics.track(BiEventsNames.WebCalendarClick, {
      first_presented_time_slot: timeSlots[0].start.toISOString(),
      number_of_time_slots: timeSlots.length,
      date_selected: selectedDay,
      date_slot_index: selectedDayIndex,
      number_of_fetched_days: Object.keys(slots).length,
      meeting_type: meetingType.lss,
    });
  }

  return (
    <Scheduler
      onDayChange={onDayChange}
      getDefaultSelectedDay={(slots) => Object.keys(slots)[0]}
      loading={isLoading}
      slots={slots}
      name={funnelInputsNameMap.lssStartTime}
    />
  );
}
