import React, { useState } from "react";
import { useParams } from "next/navigation";

import { analytics } from "@flare/analytics";

import { competenciesMap } from "@/consts/competencies";
import type { GetAvailableSlotsRequest } from "@/generated/graphql.vinny";
import {
  BookeeMeetingType,
  PracticeAreaName,
  useGetAvailableSlotsQuery,
} from "@/generated/graphql.vinny";
import { funnelInputsNameMap } from "@/modules/v2/funnel/config";
import { useFunnelAnswers } from "@/modules/v2/funnel/hooks/useFunnelAnswers";
import {
  isQualifiedInternalEmail,
  testCounty,
  testStateCode,
} from "@/modules/v2/funnel/visitor-checks/checkQualifiedInternalEmail";
import { BiEventsNames } from "@/services/analytics/event-names";

import { meetingType } from "../funnel.consts";
import type { Slots } from "../Scheduler/Scheduler";
import { Scheduler } from "../Scheduler/Scheduler";
import { getTimeZoneAbbreviation } from "../Scheduler/Scheduler.utils";
import { getFips } from "../utils/get-fips";

import { groupByDate } from "./LssScheduler.utils";

const clientTimeZone = getTimeZoneAbbreviation() || "";

export function LssSchedulerWithoutRequest() {
  const [slots, setSlots] = useState<Slots>({});
  const { funnelAnswers, setFunnelAnswers } = useFunnelAnswers();
  const { stateCode, county, email } = funnelAnswers || {};

  const { subPractice } = funnelAnswers || {};

  const params = useParams();
  const practiceUrlParam: string | undefined = Array.isArray(params?.practice)
    ? params.practice[0]
    : params?.practice;

  const practiceArea: PracticeAreaName =
    practiceUrlParam?.toUpperCase() === PracticeAreaName.Immigration
      ? PracticeAreaName.Immigration
      : PracticeAreaName.Family;

  const competencies =
    practiceArea && subPractice
      ? competenciesMap[practiceArea.toLowerCase()]?.[subPractice]
      : [];

  const [actualStateCode, actualCounty] = isQualifiedInternalEmail(
    email,
    "dlss",
  )
    ? [testStateCode, testCounty]
    : [stateCode, county];

  const fips = getFips({
    county: actualCounty,
    stateCode: actualStateCode,
  });

  const availableSlotsPayload: GetAvailableSlotsRequest = {
    meetingType: BookeeMeetingType.Lss,
    practiceArea,
    competencies,
  };

  if (practiceArea === PracticeAreaName.Family) {
    availableSlotsPayload.stateCode = actualStateCode;
    availableSlotsPayload.fips = fips;
  }

  const { loading } = useGetAvailableSlotsQuery({
    variables: {
      payload: availableSlotsPayload,
    },
    onCompleted: (data) => {
      const slotsGroupedByDate = groupByDate(data.getAvailableSlots);

      setSlots(slotsGroupedByDate);
      const [firstAvailableDate] = Object.keys(slotsGroupedByDate);

      setFunnelAnswers({
        ...funnelAnswers,
        timezone: clientTimeZone,
        meetingType: meetingType.lss,
      });

      analytics.track(BiEventsNames.WebCalendarPageView, {
        range_start: data.getAvailableSlots?.[0].start,
        number_of_fetched_days: Object.keys(slotsGroupedByDate).length,
        practice: practiceArea,
        first_presented_time_slot: slotsGroupedByDate[firstAvailableDate][0],
        number_of_time_slots: slotsGroupedByDate[firstAvailableDate].length,
        current_step_key: params?.step,
        meeting_type: meetingType.lss,
      });
    },
    onError: (error) => {
      analytics.track(BiEventsNames.WebFunnelError, {
        error_type: "get_lss_meeting_slots_error",
        error_message: error?.message,
        practice: practiceArea,
      });
    },
  });

  function onDayChange(selectedDay: string) {
    const selectedDayIndex = Object.keys(slots).indexOf(selectedDay);

    const timeSlots = slots[selectedDay];
    analytics.track(BiEventsNames.WebCalendarClick, {
      first_presented_time_slot: timeSlots[0].start.toISOString(),
      number_of_time_slots: timeSlots.length,
      date_selected: selectedDay,
      date_slot_index: selectedDayIndex,
      number_of_fetched_days: Object.keys(slots).length,
      meeting_type: meetingType.lss,
    });
  }

  return (
    <Scheduler
      onDayChange={onDayChange}
      getDefaultSelectedDay={(slots) => Object.keys(slots)[0]}
      loading={loading}
      slots={slots}
      name={funnelInputsNameMap.lssStartTime}
    />
  );
}
