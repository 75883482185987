import React, { useState } from "react";
import { Builder } from "@builder.io/sdk";
import { useParams } from "next/navigation";

import { analytics } from "@flare/analytics";

import { FUNNEL_INTAKE_SCHEDULER } from "@/builder/components-sections";
import { useGetIntakeMeetingSlotsQuery } from "@/generated/graphql.old_backend";
import { funnelInputsNameMap } from "@/modules/v2/funnel/config";
import { useFunnelAnswers } from "@/modules/v2/funnel/hooks/useFunnelAnswers";
import { BiEventsNames } from "@/services/analytics/event-names";

import { meetingType } from "../funnel.consts";
import type { Slots } from "../Scheduler/Scheduler";
import { Scheduler } from "../Scheduler/Scheduler";
import { getTimeZoneAbbreviation } from "../Scheduler/Scheduler.utils";

import { groupByDate } from "./IntakeScheduler.utils";

export const PracticeEnum = {
  family: "family",
  immigration: "immigration",
} as const;

export const NUMBER_OF_DAYS = 7;
const [today] = new Date().toISOString().split("T");

const clientTimeZone = getTimeZoneAbbreviation() || "";

export function IntakeScheduler() {
  const { funnelAnswers, setFunnelAnswers } = useFunnelAnswers();
  const [groupedIntakeSlots, setGroupedIntakeSlots] = useState<Slots>({});
  const { isEligibleForCmn } = funnelAnswers;

  const params = useParams();
  const practiceUrlParam: string | undefined = Array.isArray(params?.practice)
    ? params.practice[0]
    : params?.practice;

  const { loading } = useGetIntakeMeetingSlotsQuery({
    variables: {
      input: {
        practice: practiceUrlParam,
        numberOfDays: NUMBER_OF_DAYS,
        rangeStart: today,
      },
    },
    onCompleted: (data) => {
      const slotsGroupedByDate = groupByDate(data.intakeMeetingSlots.slots);
      const hasSlotsToday = Boolean(slotsGroupedByDate[today]);

      setGroupedIntakeSlots(slotsGroupedByDate);
      const [firstAvailableDate] = Object.keys(slotsGroupedByDate);

      setFunnelAnswers({
        ...funnelAnswers,
        timezone: clientTimeZone,
        meetingType: meetingType.intake,
      });

      analytics.track(BiEventsNames.WebCalendarPageView, {
        range_start: today,
        number_of_fetched_days: Object.keys(slotsGroupedByDate).length,
        practice: practiceUrlParam,
        timezone: clientTimeZone,
        first_presented_time_slot: slotsGroupedByDate[firstAvailableDate][0],
        number_of_time_slots: slotsGroupedByDate[firstAvailableDate].length,
        current_step_key: params?.step,
        meeting_type: meetingType.intake,
        call_me_now_visible: Boolean(isEligibleForCmn && hasSlotsToday),
      });
    },
    onError: (error) => {
      analytics.track(BiEventsNames.WebFunnelError, {
        error_type: "get_intake_meeting_slots_error",
        error_message: error?.message,
        practice: practiceUrlParam,
      });
    },
  });

  function onDayChange(selectedDay: string) {
    const selectedDayIndex =
      Object.keys(groupedIntakeSlots).indexOf(selectedDay);

    const timeSlots = groupedIntakeSlots[selectedDay];
    analytics.track(BiEventsNames.WebCalendarClick, {
      timezone: clientTimeZone,
      first_presented_time_slot: timeSlots?.[0].start?.toISOString(),
      number_of_time_slots: timeSlots?.length,
      date_selected: selectedDay,
      date_slot_index: selectedDayIndex,
      number_of_fetched_days: Object.keys(groupedIntakeSlots).length,
      meeting_type: meetingType.intake,
    });
  }

  const hasSlotsToday = Boolean(groupedIntakeSlots[today]);

  return (
    <Scheduler
      onDayChange={onDayChange}
      getDefaultSelectedDay={(slots) => Object.keys(slots)[0]}
      loading={loading}
      slots={groupedIntakeSlots}
      name={funnelInputsNameMap.eventStartTime}
      showCmn={isEligibleForCmn && hasSlotsToday}
    />
  );
}

Builder.registerComponent(IntakeScheduler, {
  name: FUNNEL_INTAKE_SCHEDULER,
  inputs: [],
});
