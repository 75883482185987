import type { VisitorStatusCode } from "@/generated/graphql.vinny";
import { Interlude } from "@/modules/v2/funnel/Interlude";
import { delay } from "@/utils/delay";
import { promiseWithMinimumDelay } from "@/utils/promiseWithMinimumDelay";

import type { FunnelMeta } from "./hooks/useFunnelMeta";
import type { Intent } from "./visitor-checks/calculateLeadScore";
import { calculateLeadScore } from "./visitor-checks/calculateLeadScore";
import { calculateLssQualification } from "./visitor-checks/calculateLssQualification";
import { checkCmnEligibility } from "./visitor-checks/checkCmnEligibility";
import { getVisitorStatusCode } from "./visitor-checks/getVisitorStatusCode";

export const modelName = "funnel-step-v-2";

/**
 * steps - pool of all possible steps
 */
export const steps = {
  "service-type": "service-type",
  "zip-code": "zip-code",
  "location-confirmation": "location-confirmation",
  "more-info": "more-info",
  "case-urgency": "case-urgency",
  unsupported: "unsupported",
  "unsupported-confirmed": "unsupported-confirmed",
  "create-meeting": "create-meeting",
  "contact-info": "contact-info",
  "contact-info-reschedule": "contact-info-reschedule",
  "contact-info-call-now": "contact-info-call-now",
  "contact-info-dlss": "contact-info-dlss",
  "state-selection": "state-selection",
  confirmation: "confirmation",
  "green-card-status": "green-card-status",
  "green-card-type": "green-card-type",
  "fiance-relationship-status": "fiance-relationship-status",
  "partner-interaction": "partner-interaction",
  "married-to-citizen": "married-to-citizen",
  "green-card-duration": "green-card-duration",
  "applicant-type": "applicant-type",
  "visa-eligibility": "visa-eligibility",
  "tourist-visa-status": "tourist-visa-status",
  "sponsor-type": "sponsor-type",
  "sponsor-status": "sponsor-status",
  "child-age": "child-age",
  "child-marital-status": "child-marital-status",
  "job-offer": "job-offer",
  "advanced-degree": "advanced-degree",
  "exceptional-ability": "exceptional-ability",
  "humanitarian-status": "humanitarian-status",
  "funding-source": "funding-source",
  "beneficiary-region": "beneficiary-region",
  "lss-checkout": "lss-checkout",
  "has-minor-children": "has-minor-children",
  "children-info": "children-info",
  "children-safety": "children-safety",
  "child-protective-services": "child-protective-services",
  "opposing-party": "opposing-party",
  "has-upcoming-court-event": "has-upcoming-court-event",
  "upcoming-event-date": "upcoming-event-date",
  "currently-represented": "currently-represented",
  "book-lss": "book-lss",
  "slot-is-taken": "slot-is-taken",
  "payment-declined": "payment-declined",
  "out-of-scope": "out-of-scope",
  "unsupported-county": "unsupported-county",
  "intake-confirmation": "intake-confirmation",
  "intake-confirmation-call-now": "intake-confirmation-call-now",
  "lss-confirmation": "lss-confirmation",
  "lss-intro": "lss-intro",
  "case-type": "case-type",
  "additional-info": "additional-info",
  "dlss-offer": "dlss-offer",
} as const satisfies Record<string, string>;

/**
 * We need to know what are shared steps
 * to be able to query them without knowing the sub-practice they belong to
 */
export const sharedSteps: Array<string> = [
  steps["zip-code"],
  steps["case-urgency"],
  steps["state-selection"],
  steps["location-confirmation"],
  steps["more-info"],
  steps["create-meeting"],
  steps["contact-info"],
  steps["contact-info-call-now"],
  steps["contact-info-dlss"],
  steps["unsupported-confirmed"],
  steps.confirmation,
  steps.unsupported,
  steps["visa-eligibility"],
  steps["applicant-type"],
  steps["funding-source"],
  steps["beneficiary-region"],
  steps["lss-checkout"],
  steps["has-minor-children"],
  steps["children-info"],
  steps["children-safety"],
  steps["child-protective-services"],
  steps["opposing-party"],
  steps["has-upcoming-court-event"],
  steps["upcoming-event-date"],
  steps["currently-represented"],
  steps["book-lss"],
  steps["slot-is-taken"],
  steps["payment-declined"],
  steps["out-of-scope"],
  steps["unsupported-county"],
  steps["intake-confirmation"],
  steps["intake-confirmation-call-now"],
  steps["lss-confirmation"],
  steps["lss-intro"],
  steps["contact-info-dlss"],
  steps["additional-info"],
  steps["dlss-offer"],
];

/**
 * practices - pool of all possible practices and their sub-practices
 */
export const practices = {
  family: [
    "child-support",
    "child-custody",
    "divorce",
    "alimony",
    "else",
    "reschedule",
  ] as const,
  immigration: [
    "green-card",
    "citizenship",
    "fiance-visa",
    "else",
    "reschedule",
  ] as const,
} satisfies Record<string, Array<string>>;

type Practices = typeof practices;
export type Steps = keyof typeof steps;

type SideEffects = Array<
  "createLead" | "updateLead" | "rejectCustomer" | "updateVisitorStatusCode"
>;

type Screens = {
  [K in Steps]+?: Step;
};

export type SubPractices = {
  firstScreen: Steps;
  screens: Screens;
};

type FunnelConfig = {
  [K in keyof Practices]: Record<Practices[K][number], SubPractices>;
};

export type FunnelAnswers = {
  advancedDegree?: string;
  applicantType?: string;
  approvedTerms?: boolean;
  beneficiaryRegion?: string;
  campaignId?: string | null;
  caseType?: string;
  caseUrgency?: string;
  checkoutStatus?: string;
  childProtectiveServices?: string;
  childrenInfo?: { age: string; name: string }[];
  childrenSafety?: string;
  county?: string;
  currentlyRepresented?: string;
  email?: string;
  eventStartTime?: string;
  exceptionalAbility?: string;
  fianceRelationshipStatus?: string;
  firstName?: string;
  fundingSource?: string;
  funnelActionId?: string;
  greenCardDuration?: string;
  greenCardStatus?: string;
  greenCardType?: string;
  hasMinorChildren?: string;
  hasUpcomingCourtEvent?: string;
  humanitarianStatus?: string;
  isChildMarried?: string;
  isChildUnderTwentyOne?: string;
  isEligibleForCmn?: boolean;
  isQualifiedForLss?: boolean;
  jobOffer?: string;
  lastName?: string;
  leadIntent?: Intent;
  leadScore?: number;
  lssStartTime?: string;
  marriedToCitizen?: string;
  meetingType?: string;
  moreInfo?: string;
  opposingPartyFirstName?: string;
  opposingPartyLastName?: string;
  opposingPartyPhone?: string;
  partnerInteraction?: string;
  phone?: string;
  practice?: keyof Practices;
  serviceType?: string;
  sessionStartTime?: Date;
  skip?: boolean;
  sponsorStatus?: string;
  sponsorType?: string;
  state?: string;
  stateCode?: string;
  stateDisplayText?: string;
  subPractice?: Practices[keyof Practices][number];
  timezone?: string;
  touristVisaStatus?: string;
  upcomingEventDate?: string;
  visaEligibility?: string;
  visitorStatusCode?: VisitorStatusCode;
  visitsCount?: number;
  zipCode?: string;
};

// Makes sure FunnelAnswers are aligned to funnelInputsNameMap
type FunnelInputsNameMap = Required<{ [K in keyof FunnelAnswers]: K }>;

export const funnelInputsNameMap: FunnelInputsNameMap = {
  // v2/funnel
  practice: "practice",

  // v2/funnel/[practice]
  subPractice: "subPractice",

  // v2/funnel/[practice]/[subPractice]
  campaignId: "campaignId",

  // case-type
  caseType: "caseType",

  // service-type
  serviceType: "serviceType",

  // sponsor-type
  sponsorType: "sponsorType",

  // sponsor-status
  sponsorStatus: "sponsorStatus",

  // child-age

  isChildUnderTwentyOne: "isChildUnderTwentyOne",

  // child-marital-status

  isChildMarried: "isChildMarried",

  // job-offer

  jobOffer: "jobOffer",

  // advanced-degree

  advancedDegree: "advancedDegree",

  //exceptional-ability

  exceptionalAbility: "exceptionalAbility",

  // humanitarian-status

  humanitarianStatus: "humanitarianStatus",

  // green-card-status
  greenCardStatus: "greenCardStatus",

  // green-card-type
  greenCardType: "greenCardType",

  // fiance-relationship-status
  fianceRelationshipStatus: "fianceRelationshipStatus",

  // partner-interaction
  partnerInteraction: "partnerInteraction",

  // applicant-type
  applicantType: "applicantType",

  // married-to-citizen
  marriedToCitizen: "marriedToCitizen",

  // green-card-duration
  greenCardDuration: "greenCardDuration",

  // visa-eligibility
  visaEligibility: "visaEligibility",

  // tourist-visa-status
  touristVisaStatus: "touristVisaStatus",

  // zip-code
  county: "county",
  zipCode: "zipCode",
  stateCode: "stateCode",
  stateDisplayText: "stateDisplayText",

  // zip-code & state-selection
  state: "state",

  // post contact-info
  isQualifiedForLss: "isQualifiedForLss", // calculateLssQualification
  funnelActionId: "funnelActionId", // createLead

  // case-urgency
  caseUrgency: "caseUrgency",

  // funding-source
  fundingSource: "fundingSource",

  // beneficiary-region
  beneficiaryRegion: "beneficiaryRegion",

  // more-info
  moreInfo: "moreInfo",
  skip: "skip",

  // post more-info
  leadScore: "leadScore", // calculateLeadScore
  leadIntent: "leadIntent", // calculateLeadScore
  sessionStartTime: "sessionStartTime", // calculateLeadScore
  visitsCount: "visitsCount", // calculateLeadScore

  // CMN eligibility
  isEligibleForCmn: "isEligibleForCmn",

  // create-meeting
  eventStartTime: "eventStartTime",
  timezone: "timezone",

  // book-lss
  lssStartTime: "lssStartTime",

  // create-meeting & book-lss
  meetingType: "meetingType",

  // contact-info & contact-info-reschedule && contact-info-dlss
  firstName: "firstName",
  lastName: "lastName",
  phone: "phone",
  email: "email",
  approvedTerms: "approvedTerms",

  // has-minor-children
  hasMinorChildren: "hasMinorChildren",

  // children-info
  childrenInfo: "childrenInfo",

  // children-safety
  childrenSafety: "childrenSafety",

  // child-protective-services
  childProtectiveServices: "childProtectiveServices",

  // opposing-party
  opposingPartyFirstName: "opposingPartyFirstName",
  opposingPartyLastName: "opposingPartyLastName",
  opposingPartyPhone: "opposingPartyPhone",

  // has-upcoming-court-event
  hasUpcomingCourtEvent: "hasUpcomingCourtEvent",

  // upcoming-event-date
  upcomingEventDate: "upcomingEventDate",

  // currently-represented
  currentlyRepresented: "currentlyRepresented",

  // post currently-represented
  visitorStatusCode: "visitorStatusCode", // updateVisitorStatusCode

  // lss-checkout
  checkoutStatus: "checkoutStatus",
} as const;

export type FunnelData = {
  answers?: FunnelAnswers;
  experiments?: Record<string, boolean>;
  meta?: FunnelMeta;
};

type RenderInterludeArgs = {
  answers: FunnelAnswers;
  experiments: { [key: string]: boolean };
};

type RenderInterlude = ({
  answers,
  experiments,
}: RenderInterludeArgs) => React.ReactNode | null;

export type Step = {
  next: <T extends FunnelData>(stepData?: T) => Promise<Steps | undefined>;
  renderInterlude?: RenderInterlude;
  sanitize?: boolean;
  sideEffects?: SideEffects;
};

const DELAY_MS = 2000;

export const funnelConfig = {
  family: {
    divorce: {
      firstScreen: "service-type",
      screens: {
        "service-type": {
          next: async () => "zip-code",
        },
        "zip-code": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { skip, state } = answers;

            if (skip) {
              return "state-selection";
            }

            if (state === "unsupported") {
              return "unsupported";
            }

            return "location-confirmation";
          },
        },
        "state-selection": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { state } = answers;

            if (state === "unsupported") {
              return "unsupported";
            }

            return "location-confirmation";
          },
        },
        unsupported: {
          next: async () => "unsupported-confirmed",
          sideEffects: ["rejectCustomer"],
          sanitize: true,
        },
        "unsupported-confirmed": {
          next: async () => "unsupported-confirmed",
        },
        "location-confirmation": {
          next: async () => "case-urgency",
        },
        "case-urgency": {
          next: async () => "more-info",
        },
        "more-info": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers, experiments }) => {
            const { abTestHighIntent } = experiments;
            const { intent: leadIntent, score } = calculateLeadScore(
              answers,
              steps["more-info"],
            );

            if (abTestHighIntent) {
              checkCmnEligibility({ leadIntent, leadScore: score });

              if (leadIntent === "high") {
                const isQualifiedForLss = await promiseWithMinimumDelay(
                  calculateLssQualification(),
                  DELAY_MS,
                );

                if (isQualifiedForLss) return "dlss-offer";
              }
            }

            return "create-meeting";
          },
          renderInterlude: ({ answers, experiments }) => {
            const { leadIntent } = answers;
            const { abTestHighIntent } = experiments;
            return abTestHighIntent && leadIntent === "high" ? (
              <Interlude heading="Just a few moments" />
            ) : null;
          },
        },
        "dlss-offer": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { skip } = answers;

            if (skip) {
              return "create-meeting";
            }

            return "has-minor-children";
          },
        },
        "create-meeting": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) =>
            answers?.eventStartTime === "call_me_now"
              ? "contact-info-call-now"
              : "contact-info",
        },
        "contact-info": {
          next: async () => {
            await promiseWithMinimumDelay(
              calculateLssQualification(),
              DELAY_MS,
            );
            return "intake-confirmation";
          },
          sideEffects: ["createLead"],
          sanitize: true,
          renderInterlude: () => <Interlude heading="Confirming your call" />,
        },

        "contact-info-call-now": {
          next: async () => "intake-confirmation-call-now",
          sideEffects: ["createLead"],
          sanitize: true,
          renderInterlude: () => <Interlude heading="Confirming your call" />,
        },
        "contact-info-dlss": {
          next: async () => "lss-checkout",
          sideEffects: ["createLead"],
          sanitize: true,
        },
        "intake-confirmation": {
          next: async () => "lss-intro",
        },
        "intake-confirmation-call-now": {
          next: async () => undefined,
        },
        "lss-intro": {
          next: async () => "has-minor-children",
        },

        // Digital Intake steps
        "has-minor-children": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { hasMinorChildren } = answers;
            if (hasMinorChildren === "Yes") return "children-info";
            if (hasMinorChildren === "No") return "opposing-party";
          },
          sideEffects: ["updateLead"],
        },
        "children-info": {
          next: async () => "children-safety",
        },
        "children-safety": {
          next: async () => "child-protective-services",
        },
        "child-protective-services": {
          next: async () => "opposing-party",
        },
        "opposing-party": {
          next: async () => "has-upcoming-court-event",
        },
        "has-upcoming-court-event": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { hasUpcomingCourtEvent } = answers;
            if (hasUpcomingCourtEvent === "Yes") return "upcoming-event-date";
            if (hasUpcomingCourtEvent === "No") return "currently-represented";
            if (hasUpcomingCourtEvent === "I have a different event")
              return "upcoming-event-date";
          },
        },
        "upcoming-event-date": {
          next: async () => "currently-represented",
        },
        "currently-represented": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const statusCodePromise = getVisitorStatusCode({
              answers,
              currentStep: steps["currently-represented"],
            });

            const statusCode = await promiseWithMinimumDelay(
              statusCodePromise,
              answers?.moreInfo ? DELAY_MS : 0,
            );

            if (statusCode !== "ELG1" && statusCode !== "ERROR") {
              return "out-of-scope";
            }
            return answers.moreInfo ? "book-lss" : "additional-info";
          },
          sideEffects: ["updateVisitorStatusCode"],
          renderInterlude: ({ answers }) => {
            return answers?.moreInfo ? (
              <Interlude
                heading="Reviewing your answers"
                subHeading="Just a few more moments"
              />
            ) : null;
          },
        },
        "additional-info": {
          next: async () => {
            await delay(DELAY_MS);
            return "book-lss";
          },
          sideEffects: ["updateVisitorStatusCode"],
          renderInterlude: () => (
            <Interlude
              heading="Reviewing your answers"
              subHeading="Just a few more moments"
            />
          ),
        },
        // Direct LSS steps
        "book-lss": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers, experiments }) => {
            const { leadIntent } = answers;
            const { abTestHighIntent } = experiments;
            if (leadIntent === "high" && abTestHighIntent) {
              return "contact-info-dlss";
            }
            return "lss-checkout";
          },
        },
        "lss-checkout": {
          // @ts-expect-error: Maybe undefined
          next: ({ answers, meta }) => {
            const { skip } = answers;

            if (skip) return "create-meeting";

            const { checkoutStatus } = meta;
            return checkoutStatus;
          },
        },
        "slot-is-taken": {
          next: async () => "book-lss",
        },
        "payment-declined": {
          next: async () => "lss-checkout",
        },
        "lss-confirmation": {
          next: async () => undefined,
        },
        "out-of-scope": {
          next: async () => undefined,
        },
      },
    },
    alimony: {
      firstScreen: "service-type",
      screens: {
        "service-type": {
          next: async () => "zip-code",
        },
        "zip-code": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { skip, state } = answers;

            if (skip) {
              return "state-selection";
            }

            if (state === "unsupported") {
              return "unsupported";
            }

            return "location-confirmation";
          },
        },
        "state-selection": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { state } = answers;

            if (state === "unsupported") {
              return "unsupported";
            }

            return "location-confirmation";
          },
        },
        unsupported: {
          next: async () => "unsupported-confirmed",
          sideEffects: ["rejectCustomer"],
          sanitize: true,
        },
        "unsupported-confirmed": {
          next: async () => "unsupported-confirmed",
        },
        "location-confirmation": {
          next: async () => "case-urgency",
        },
        "case-urgency": {
          next: async () => "more-info",
        },
        "more-info": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            calculateLeadScore(answers, steps["more-info"]);
            return "create-meeting";
          },
        },
        "create-meeting": {
          next: async () => "contact-info",
        },
        "contact-info": {
          next: async () => {
            return "intake-confirmation";
          },
          sideEffects: ["createLead"],
          sanitize: true,
        },
        "intake-confirmation": {
          next: async () => undefined,
        },
        "out-of-scope": {
          next: async () => undefined,
        },
      },
    },
    "child-custody": {
      firstScreen: "service-type",
      screens: {
        "service-type": {
          next: async () => "zip-code",
        },
        "zip-code": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { skip, state } = answers;

            if (skip) {
              return "state-selection";
            }

            if (state === "unsupported") {
              return "unsupported";
            }

            return "location-confirmation";
          },
        },
        "state-selection": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { state } = answers;

            if (state === "unsupported") {
              return "unsupported";
            }

            return "location-confirmation";
          },
        },
        unsupported: {
          next: async () => "unsupported-confirmed",
          sideEffects: ["rejectCustomer"],
          sanitize: true,
        },
        "unsupported-confirmed": {
          next: async () => "unsupported-confirmed",
        },
        "location-confirmation": {
          next: async () => "case-urgency",
        },
        "case-urgency": {
          next: async () => "more-info",
        },
        "more-info": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers, experiments }) => {
            const { abTestHighIntent } = experiments;
            const { intent: leadIntent, score } = calculateLeadScore(
              answers,
              steps["more-info"],
            );

            if (abTestHighIntent) {
              checkCmnEligibility({ leadIntent, leadScore: score });

              if (leadIntent === "high") {
                const isQualifiedForLss = await promiseWithMinimumDelay(
                  calculateLssQualification(),
                  DELAY_MS,
                );

                if (isQualifiedForLss) return "dlss-offer";
              }
            }

            return "create-meeting";
          },
          renderInterlude: ({ answers, experiments }) => {
            const { leadIntent } = answers;
            const { abTestHighIntent } = experiments;
            return abTestHighIntent && leadIntent === "high" ? (
              <Interlude heading="Just a few moments" />
            ) : null;
          },
        },
        "dlss-offer": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { skip } = answers;

            if (skip) {
              return "create-meeting";
            }

            return "has-minor-children";
          },
        },
        "create-meeting": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) =>
            answers?.eventStartTime === "call_me_now"
              ? "contact-info-call-now"
              : "contact-info",
        },
        "contact-info": {
          next: async () => {
            await promiseWithMinimumDelay(
              calculateLssQualification(),
              DELAY_MS,
            );
            return "intake-confirmation";
          },
          sideEffects: ["createLead"],
          sanitize: true,
          renderInterlude: () => <Interlude heading="Confirming your call" />,
        },
        "contact-info-call-now": {
          next: async () => "intake-confirmation-call-now",
          sideEffects: ["createLead"],
          sanitize: true,
          renderInterlude: () => <Interlude heading="Confirming your call" />,
        },
        "contact-info-dlss": {
          next: async () => "lss-checkout",
          sideEffects: ["createLead"],
          sanitize: true,
        },
        "intake-confirmation": {
          next: async () => "lss-intro",
        },
        "intake-confirmation-call-now": {
          next: async () => undefined,
        },
        "lss-intro": {
          next: async () => "has-minor-children",
        },
        // Digital Intake steps
        "has-minor-children": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { hasMinorChildren } = answers;
            if (hasMinorChildren === "Yes") return "children-info";
            if (hasMinorChildren === "No") return "opposing-party";
          },
          sideEffects: ["updateLead"],
        },
        "children-info": {
          next: async () => "children-safety",
        },
        "children-safety": {
          next: async () => "child-protective-services",
        },
        "child-protective-services": {
          next: async () => "opposing-party",
        },
        "opposing-party": {
          next: async () => "has-upcoming-court-event",
        },
        "has-upcoming-court-event": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { hasUpcomingCourtEvent } = answers;
            if (hasUpcomingCourtEvent === "Yes") return "upcoming-event-date";
            if (hasUpcomingCourtEvent === "No") return "currently-represented";
            if (hasUpcomingCourtEvent === "I have a different event")
              return "upcoming-event-date";
          },
        },
        "upcoming-event-date": {
          next: async () => "currently-represented",
        },
        "currently-represented": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const statusCodePromise = getVisitorStatusCode({
              answers,
              currentStep: steps["currently-represented"],
            });

            const statusCode = await promiseWithMinimumDelay(
              statusCodePromise,
              answers?.moreInfo ? DELAY_MS : 0,
            );

            if (statusCode !== "ELG1" && statusCode !== "ERROR") {
              return "out-of-scope";
            }
            return answers.moreInfo ? "book-lss" : "additional-info";
          },
          sideEffects: ["updateVisitorStatusCode"],
          renderInterlude: ({ answers }) => {
            return answers?.moreInfo ? (
              <Interlude
                heading="Reviewing your answers"
                subHeading="Just a few more moments"
              />
            ) : null;
          },
        },
        "additional-info": {
          next: async () => {
            await delay(DELAY_MS);
            return "book-lss";
          },
          sideEffects: ["updateVisitorStatusCode"],
          renderInterlude: () => (
            <Interlude
              heading="Reviewing your answers"
              subHeading="Just a few more moments"
            />
          ),
        },
        // Direct LSS steps
        "book-lss": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers, experiments }) => {
            const { leadIntent } = answers;
            const { abTestHighIntent } = experiments;
            if (leadIntent === "high" && abTestHighIntent) {
              return "contact-info-dlss";
            }
            return "lss-checkout";
          },
        },
        "lss-checkout": {
          // @ts-expect-error: Maybe undefined
          next: ({ answers, meta }) => {
            const { skip } = answers;

            if (skip) return "create-meeting";

            const { checkoutStatus } = meta;
            return checkoutStatus;
          },
        },
        "slot-is-taken": {
          next: async () => "book-lss",
        },
        "payment-declined": {
          next: async () => "lss-checkout",
        },
        "lss-confirmation": {
          next: async () => undefined,
        },
        "out-of-scope": {
          next: async () => undefined,
        },
      },
    },
    "child-support": {
      firstScreen: "service-type",
      screens: {
        "service-type": {
          next: async () => "zip-code",
        },
        "zip-code": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { skip, state } = answers;

            if (skip) {
              return "state-selection";
            }

            if (state === "unsupported") {
              return "unsupported";
            }

            return "location-confirmation";
          },
        },
        "state-selection": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { state } = answers;

            if (state === "unsupported") {
              return "unsupported";
            }

            return "location-confirmation";
          },
        },
        unsupported: {
          next: async () => "unsupported-confirmed",
          sideEffects: ["rejectCustomer"],
          sanitize: true,
        },
        "unsupported-confirmed": {
          next: async () => "unsupported-confirmed",
        },
        "location-confirmation": {
          next: async () => "case-urgency",
        },
        "case-urgency": {
          next: async () => "more-info",
        },
        "more-info": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers, experiments }) => {
            const { abTestHighIntent } = experiments;
            const { intent: leadIntent, score } = calculateLeadScore(
              answers,
              steps["more-info"],
            );

            if (abTestHighIntent) {
              checkCmnEligibility({ leadIntent, leadScore: score });

              if (leadIntent === "high") {
                const isQualifiedForLss = await promiseWithMinimumDelay(
                  calculateLssQualification(),
                  DELAY_MS,
                );

                if (isQualifiedForLss) return "dlss-offer";
              }
            }

            return "create-meeting";
          },
          renderInterlude: ({ answers, experiments }) => {
            const { leadIntent } = answers;
            const { abTestHighIntent } = experiments;
            return abTestHighIntent && leadIntent === "high" ? (
              <Interlude heading="Just a few moments" />
            ) : null;
          },
        },
        "dlss-offer": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { skip } = answers;

            if (skip) {
              return "create-meeting";
            }

            return "has-minor-children";
          },
        },
        "create-meeting": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) =>
            answers?.eventStartTime === "call_me_now"
              ? "contact-info-call-now"
              : "contact-info",
        },
        "contact-info": {
          next: async () => {
            await promiseWithMinimumDelay(
              calculateLssQualification(),
              DELAY_MS,
            );
            return "intake-confirmation";
          },
          sideEffects: ["createLead"],
          sanitize: true,
          renderInterlude: () => <Interlude heading="Confirming your call" />,
        },
        "contact-info-call-now": {
          next: async () => "intake-confirmation-call-now",
          sideEffects: ["createLead"],
          sanitize: true,
          renderInterlude: () => <Interlude heading="Confirming your call" />,
        },
        "contact-info-dlss": {
          next: async () => "lss-checkout",
          sideEffects: ["createLead"],
          sanitize: true,
        },
        "intake-confirmation": {
          next: async () => "lss-intro",
        },
        "intake-confirmation-call-now": {
          next: async () => undefined,
        },
        "lss-intro": {
          next: async () => "has-minor-children",
        },
        // Digital Intake steps
        "has-minor-children": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { hasMinorChildren } = answers;
            if (hasMinorChildren === "Yes") return "children-info";
            if (hasMinorChildren === "No") return "opposing-party";
          },
          sideEffects: ["updateLead"],
        },
        "children-info": {
          next: async () => "children-safety",
        },
        "children-safety": {
          next: async () => "child-protective-services",
        },
        "child-protective-services": {
          next: async () => "opposing-party",
        },
        "opposing-party": {
          next: async () => "has-upcoming-court-event",
        },
        "has-upcoming-court-event": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { hasUpcomingCourtEvent } = answers;
            if (hasUpcomingCourtEvent === "Yes") return "upcoming-event-date";
            if (hasUpcomingCourtEvent === "No") return "currently-represented";
            if (hasUpcomingCourtEvent === "I have a different event")
              return "upcoming-event-date";
          },
        },
        "upcoming-event-date": {
          next: async () => "currently-represented",
        },
        "currently-represented": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const statusCodePromise = getVisitorStatusCode({
              answers,
              currentStep: steps["currently-represented"],
            });

            const statusCode = await promiseWithMinimumDelay(
              statusCodePromise,
              answers?.moreInfo ? DELAY_MS : 0,
            );

            if (statusCode !== "ELG1" && statusCode !== "ERROR") {
              return "out-of-scope";
            }
            return answers.moreInfo ? "book-lss" : "additional-info";
          },
          sideEffects: ["updateVisitorStatusCode"],
          renderInterlude: ({ answers }) => {
            return answers?.moreInfo ? (
              <Interlude
                heading="Reviewing your answers"
                subHeading="Just a few more moments"
              />
            ) : null;
          },
        },
        "additional-info": {
          next: async () => {
            await delay(DELAY_MS);
            return "book-lss";
          },
          sideEffects: ["updateVisitorStatusCode"],
          renderInterlude: () => (
            <Interlude
              heading="Reviewing your answers"
              subHeading="Just a few more moments"
            />
          ),
        },
        // Direct LSS steps
        "book-lss": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers, experiments }) => {
            const { leadIntent } = answers;
            const { abTestHighIntent } = experiments;
            if (leadIntent === "high" && abTestHighIntent) {
              return "contact-info-dlss";
            }
            return "lss-checkout";
          },
        },
        "lss-checkout": {
          // @ts-expect-error: Maybe undefined
          next: ({ answers, meta }) => {
            const { skip } = answers;

            if (skip) return "create-meeting";

            const { checkoutStatus } = meta;
            return checkoutStatus;
          },
        },
        "slot-is-taken": {
          next: async () => "book-lss",
        },
        "payment-declined": {
          next: async () => "lss-checkout",
        },
        "lss-confirmation": {
          next: async () => undefined,
        },
        "out-of-scope": {
          next: async () => undefined,
        },
      },
    },
    else: {
      firstScreen: "case-type",
      screens: {
        "case-type": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { caseType } = answers;

            switch (caseType.toLowerCase()) {
              case "none of the above":
                return "zip-code";

              case "child protective services involvement":
                return "child-protective-services";

              default:
                return "out-of-scope";
            }
          },
        },
        "zip-code": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { skip, state } = answers;

            if (skip) {
              return "state-selection";
            }

            if (state === "unsupported") {
              return "unsupported";
            }

            return "location-confirmation";
          },
        },
        "state-selection": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { state } = answers;

            if (state === "unsupported") {
              return "unsupported";
            }

            return "location-confirmation";
          },
        },
        unsupported: {
          next: async () => "unsupported-confirmed",
          sideEffects: ["rejectCustomer"],
          sanitize: true,
        },
        "unsupported-confirmed": {
          next: async () => "unsupported-confirmed",
        },
        "location-confirmation": {
          next: async () => "case-urgency",
        },
        "case-urgency": {
          next: async () => "more-info",
        },
        "more-info": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers, experiments }) => {
            const { abTestHighIntent } = experiments;
            const { intent: leadIntent, score } = calculateLeadScore(
              answers,
              steps["more-info"],
            );

            if (abTestHighIntent) {
              checkCmnEligibility({ leadIntent, leadScore: score });
            }

            return "create-meeting";
          },
        },
        "create-meeting": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) =>
            answers?.eventStartTime === "call_me_now"
              ? "contact-info-call-now"
              : "contact-info",
        },
        "contact-info": {
          next: async () => "intake-confirmation",
          sideEffects: ["createLead"],
          sanitize: true,
        },

        "contact-info-call-now": {
          next: async () => "intake-confirmation-call-now",
          sideEffects: ["createLead"],
          sanitize: true,
        },

        "intake-confirmation": {
          next: async () => undefined,
        },

        "intake-confirmation-call-now": {
          next: async () => undefined,
        },

        "child-protective-services": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { childProtectiveServices } = answers;
            return childProtectiveServices.toLowerCase() === "yes"
              ? "out-of-scope"
              : "zip-code";
          },
        },
        "out-of-scope": {
          next: async () => undefined,
        },
      },
    },
    reschedule: {
      firstScreen: "create-meeting",
      screens: {
        "create-meeting": {
          next: async () => "contact-info-reschedule",
        },
        "contact-info-reschedule": {
          next: async () => "intake-confirmation",
          sideEffects: ["createLead"],
          sanitize: true,
        },
        "intake-confirmation": {
          next: async () => undefined,
        },
      },
    },
  },
  immigration: {
    "green-card": {
      firstScreen: "service-type",
      screens: {
        "service-type": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers, experiments }) => {
            const { serviceType } = answers;
            const { abTestImmigrationEligibility } = experiments;
            if (
              abTestImmigrationEligibility &&
              serviceType === "New green card application"
            ) {
              return "sponsor-type";
            }

            if (
              abTestImmigrationEligibility &&
              ["Removal of conditions", "Green card renewal"].includes(
                serviceType,
              )
            ) {
              return "green-card-status";
            }

            if (abTestImmigrationEligibility) {
              return "case-urgency";
            }

            return "applicant-type";
          },
        },
        "green-card-status": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { greenCardStatus } = answers;
            if (
              greenCardStatus === "I have a green card and live in the U.S."
            ) {
              return "green-card-type";
            }
            return "out-of-scope";
          },
        },
        "green-card-type": {
          next: async () => "case-urgency",
        },
        "sponsor-type": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { sponsorType } = answers;
            if (sponsorType === "employer") return "job-offer";
            if (sponsorType === "I don’t have a sponsor")
              return "advanced-degree";
            return "sponsor-status";
          },
        },
        "sponsor-status": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { sponsorType, sponsorStatus } = answers;
            if (sponsorStatus === "U.S Citizen") {
              if (sponsorType === "child") return "child-age";
              return "case-urgency";
            }
            if (sponsorStatus === "Green card holder") {
              if (sponsorType === "spouse") return "case-urgency";
              if (sponsorType === "parent") return "child-age";
              return "out-of-scope";
            }
            return "out-of-scope";
          },
        },
        "child-age": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { sponsorType, isChildUnderTwentyOne } = answers;
            if (sponsorType === "parent") {
              if (isChildUnderTwentyOne === "Yes") return "case-urgency";
              return "child-marital-status";
            }
            if (sponsorType === "child" && isChildUnderTwentyOne === "No") {
              return "case-urgency";
            }
            return "out-of-scope";
          },
        },
        "child-marital-status": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { isChildMarried } = answers;
            if (isChildMarried === "No") {
              return "case-urgency";
            }
            return "out-of-scope";
          },
        },
        "job-offer": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { jobOffer } = answers;
            if (jobOffer === "No") return "out-of-scope";
            return "case-urgency";
          },
        },
        "advanced-degree": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { advancedDegree } = answers;
            if (advancedDegree === "No") return "exceptional-ability";
            return "case-urgency";
          },
        },
        "exceptional-ability": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { exceptionalAbility } = answers;
            if (exceptionalAbility === "No") return "humanitarian-status";
            return "case-urgency";
          },
        },
        "humanitarian-status": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { humanitarianStatus } = answers;
            if (humanitarianStatus === "No") return "out-of-scope";
            return "case-urgency";
          },
        },
        "applicant-type": {
          next: async () => "case-urgency",
        },
        "case-urgency": {
          // @ts-expect-error: Maybe undefined
          next: async ({ experiments }) => {
            const { abTestImmigrationEligibility } = experiments;

            if (abTestImmigrationEligibility) {
              return "more-info";
            }

            return "funding-source";
          },
        },
        "funding-source": {
          next: async () => "beneficiary-region",
        },
        "beneficiary-region": {
          next: async () => "more-info",
        },
        "more-info": {
          // @ts-expect-error: Maybe undefined
          next: async ({ experiments }) => {
            const { abTestIsQualifiedForLssImmigration } = experiments;

            if (abTestIsQualifiedForLssImmigration) {
              const isQualifiedForLss = await promiseWithMinimumDelay(
                calculateLssQualification(),
                DELAY_MS,
              );
              if (isQualifiedForLss) {
                return "book-lss";
              }
            }
            return "create-meeting";
          },
        },

        "create-meeting": {
          next: async () => "contact-info",
        },
        "contact-info": {
          next: async () => "intake-confirmation",
          sanitize: true,
          sideEffects: ["createLead"],
          renderInterlude: () => <Interlude heading="Confirming your call" />,
        },
        "intake-confirmation": {
          next: async () => undefined,
        },

        // Direct LSS steps
        "book-lss": { next: async () => "contact-info-dlss" },
        "contact-info-dlss": {
          next: async () => "lss-checkout",
          sanitize: true,
          sideEffects: ["createLead"],
        },
        "lss-checkout": {
          // @ts-expect-error: Maybe undefined
          next: ({ meta }) => {
            const { checkoutStatus } = meta;
            return checkoutStatus;
          },
        },
        "slot-is-taken": {
          next: async () => "book-lss",
        },
        "payment-declined": {
          next: async () => "lss-checkout",
        },
        "lss-confirmation": {
          next: async () => undefined,
        },

        "out-of-scope": {
          next: async () => undefined,
        },
      },
    },
    citizenship: {
      firstScreen: "service-type",
      screens: {
        "service-type": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers, experiments }) => {
            const { serviceType } = answers;
            const { abTestImmigrationEligibility } = experiments;

            if (!abTestImmigrationEligibility) {
              return "applicant-type";
            }

            if (serviceType === "I’m a green card holder") {
              return "married-to-citizen";
            }

            if (serviceType === "Something else") {
              return "out-of-scope";
            }

            return "case-urgency";
          },
        },
        "married-to-citizen": {
          next: async () => "green-card-duration",
        },
        "green-card-duration": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { marriedToCitizen, greenCardDuration } = answers;

            let isInScope = greenCardDuration === "4.5+ years";
            if (marriedToCitizen === "Yes") {
              isInScope = ["2-4.5 years", "4.5+ years"].includes(
                greenCardDuration,
              );
            }
            return isInScope ? "case-urgency" : "out-of-scope";
          },
        },
        "applicant-type": {
          next: async () => "case-urgency",
        },
        "case-urgency": {
          // @ts-expect-error: Maybe undefined
          next: async ({ experiments }) => {
            const { abTestImmigrationEligibility } = experiments;
            if (abTestImmigrationEligibility) {
              return "more-info";
            }
            return "funding-source";
          },
        },
        "funding-source": {
          next: async () => "beneficiary-region",
        },
        "beneficiary-region": {
          next: async () => "more-info",
        },
        "more-info": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            calculateLeadScore(answers, steps["more-info"]);
            return "create-meeting";
          },
        },
        "create-meeting": {
          next: async () => "contact-info",
        },
        "contact-info": {
          next: async () => "intake-confirmation",
          sanitize: true,
          sideEffects: ["createLead"],
        },
        "intake-confirmation": {
          next: async () => undefined,
        },
        "out-of-scope": {
          next: async () => undefined,
        },
      },
    },
    "fiance-visa": {
      firstScreen: "service-type",
      screens: {
        "service-type": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers, experiments }) => {
            const { abTestImmigrationEligibility } = experiments;
            const { fianceRelationshipStatus } = answers;

            if (!abTestImmigrationEligibility) {
              return "applicant-type";
            }

            if (fianceRelationshipStatus === "Something else") {
              return "out-of-scope";
            }

            return "partner-interaction";
          },
        },
        "applicant-type": {
          next: async () => "case-urgency",
        },
        "partner-interaction": {
          next: async () => "case-urgency",
        },
        "case-urgency": {
          // @ts-expect-error: Maybe undefined
          next: async ({ experiments }) => {
            const { abTestImmigrationEligibility } = experiments;
            if (abTestImmigrationEligibility) {
              return "more-info";
            }
            return "funding-source";
          },
        },
        "funding-source": {
          next: async () => "beneficiary-region",
        },
        "beneficiary-region": {
          next: async () => "more-info",
        },
        "more-info": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            calculateLeadScore(answers, steps["more-info"]);
            return "create-meeting";
          },
        },
        "create-meeting": {
          next: async () => "contact-info",
        },
        "contact-info": {
          next: async () => "intake-confirmation",
          sanitize: true,
          sideEffects: ["createLead"],
        },
        "intake-confirmation": {
          next: async () => undefined,
        },
        "out-of-scope": {
          next: async () => undefined,
        },
      },
    },
    else: {
      firstScreen: "service-type",
      screens: {
        "service-type": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers, experiments }) => {
            const { serviceType } = answers;
            const { abTestImmigrationEligibility } = experiments;
            if (!abTestImmigrationEligibility) {
              return "applicant-type";
            }

            if (serviceType === "else") {
              return "case-urgency";
            }

            if (serviceType === "Tourist visa") {
              return "tourist-visa-status";
            }

            return "visa-eligibility";
          },
        },
        "tourist-visa-status": {
          next: async () => "case-urgency",
        },
        "visa-eligibility": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            const { serviceType, visaEligibility } = answers;

            const studentInScope =
              serviceType === "Student visa" &&
              visaEligibility === "I’ve been accepted to school in the U.S.";

            const employmentInScope =
              serviceType === "Employment visa" &&
              visaEligibility === "I have a job offer from a U.S. employer";

            const investorInScope =
              serviceType === "Investor visa" &&
              [
                "I have an investment in the U.S.",
                "I’m considering a few investment options",
              ].includes(visaEligibility);

            const isInScope =
              studentInScope || employmentInScope || investorInScope;

            if (!isInScope) {
              return "out-of-scope";
            }

            return "case-urgency";
          },
        },
        "applicant-type": {
          next: async () => "case-urgency",
        },
        "case-urgency": {
          // @ts-expect-error: Maybe undefined
          next: async ({ experiments }) => {
            const { abTestImmigrationEligibility } = experiments;
            if (abTestImmigrationEligibility) {
              return "more-info";
            }
            return "funding-source";
          },
        },
        "funding-source": {
          next: async () => "beneficiary-region",
        },
        "beneficiary-region": {
          next: async () => "more-info",
        },
        "more-info": {
          // @ts-expect-error: Maybe undefined
          next: async ({ answers }) => {
            calculateLeadScore(answers, steps["more-info"]);
            return "create-meeting";
          },
        },
        "create-meeting": {
          next: async () => "contact-info",
        },
        "contact-info": {
          next: async () => "intake-confirmation",
          sanitize: true,
          sideEffects: ["createLead"],
        },
        "intake-confirmation": {
          next: async () => undefined,
        },
        "out-of-scope": {
          next: async () => undefined,
        },
      },
    },
    reschedule: {
      firstScreen: "create-meeting",
      screens: {
        "create-meeting": {
          next: async () => "contact-info-reschedule",
        },
        "contact-info-reschedule": {
          next: async () => "intake-confirmation",
          sideEffects: ["createLead"],
          sanitize: true,
        },
        "intake-confirmation": {
          next: async () => undefined,
        },
      },
    },
  },
} satisfies FunnelConfig;
